* {
    box-sizing: border-box;
}

body {
    overflow: hidden;
    background-color: #282c34;
    height: 100vh;
    width: 100vw;
    font-size: calc(10px + 2vmin);
    color: white;
}

#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.App {
    text-align: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: initial;
}

a {
    color: #61dafb;
}

.content {
    height: 100%;
    padding: 25px;
    overflow-y: auto;
}

.heading {
    font-weight: bolder;
    color: #b2cdff;
}